import Swal from "sweetalert2"

export function sweetAlerts() {
    function sweetSuccess(text) {
        Swal.fire({
            icon: 'success',
            text: text,
            confirmButtonColor: '#005985',
            iconColor: '#66CC99'
        })
    }

    return { sweetSuccess }
}


// // TODO: Config Sweet Alerts 2
// window.Swal = require('sweetalert2');
// // * Store Notify
// window.SwalStore = {
//     icon: "success",
//     text: "Informacion registrada exitosamente."
// };
// // * Update Notify
// window.SwalUpdate = {
//     icon: "success",
//     text: "Se han actualizado los registros exitosamente."
// }
// // * Error Notify
// window.SwalError = {
//     icon: "error",
//     text: "Por favor verifica nuevamente la informacion."
// }
// // * Server error Notify
// window.SwalServerError = {
//     icon: "error",
//     text: "Hubo un error en el servidor, intentalo de nuevo mas tarde."
// }
// // * Error Notify
// window.SwalDelete = {
//     icon: "success",
//     text: "Registro eliminado exitosamente."
// }
